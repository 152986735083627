import React, { Children, Component } from "react";
import Clock from "react-live-clock";
import Modal from "react-awesome-modal";
import { Link } from "react-router-dom";
export default class Layout extends Component {
  render() {
    return (
      <div>
        {" "}
        <div className="row">
          <img
            src="/ons-logo-son.png"
            className="rounded mx-auto d-block mt-2 img-responsive  "
            alt="Ons Altın"
            style={{ width: "20em" }}
          />
     
          <h2>
            <Clock
              format="HH:mm:ss"
              interval={1000}
              ticking={true}
              style={{ color: "#c5a559" }}
              className="float-right mr-2"
            />
          </h2>
        </div>
        <div className="row d-flex justify-content-center mt-5">
            <Link  className="btn mr-2" style={{color:"white",background:"#c5a559"}} to="/">Makas</Link>
            <Link className="btn mr-2" style={{color:"white",background:"#c5a559"}} to="/gr">GR</Link>
            <Link className="btn mr-2" style={{color:"white",background:"#c5a559"}} to="/work">Sarrafiye</Link>
            <Link className="btn mr-2" style={{color:"white",background:"#c5a559"}} to="/message">Mesaj</Link>
        </div>
      </div>
    );
  }
}
