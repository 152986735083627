import axios from "axios";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Clock from "react-live-clock";
import Modal from "react-awesome-modal";
import OnsGr from "./onsGr";
import OnsMessage from "./onsMessage";
import OnsWorkman from "./onsWorkman";

require("dotenv").config();

class SpicaQuestionAdd extends Component {
  constructor() {
    super();

    this.state = {
      usdBuy: "0",
      usdSale: "0",
      eurBuy: "0",
      eurSale: "0",
      specialBuy: "0",
      specialSale: "0",
      scrap22Buy: "0",
      scrap22Sale: "0",
      onsGoldBuy: "0",
      onsGoldSale: "0",
      informationMessage: "",
      visible: true,
      showMe: false,
      username: "",
      password: "",
    };
    this.onChange = this.onChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitSingIn = this.handleSubmitSingIn.bind(this);
  }
  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    const { REACT_APP_API_URL } = process.env;

    axios.get(REACT_APP_API_URL + "ons/getPrice").then((res) => {
      const obj = res.data.response;
      const data = res.data.responseMessage;
      console.log(obj.Doviz[0].buy);
      this.setState({
        usdBuy: obj.Doviz[0].buy,
        usdSale: obj.Doviz[0].sales,
        eurBuy: obj.Doviz[1].buy,
        eurSale: obj.Doviz[1].sales,
        specialBuy: obj.Gold[0].buy,
        specialSale: obj.Gold[0].sales,
        scrap22Buy: obj.Gold[1].buy,
        scrap22Sale: obj.Gold[1].sales,
        onsGoldBuy: obj.Gold[6].buy,
        onsGoldSale: obj.Gold[6].sales,
        informationMessage: data.message,
      });
    });
  }

  //login
  handleSubmitSingIn(e) {
    e.preventDefault();
    const { REACT_APP_API_URL } = process.env;

    const data = {
      username: this.state.username,
      password: this.state.password,
    };
    if (
      this.state.username === null ||
      this.state.username === "" ||
      this.state.password === null ||
      this.state.password === ""
    ) {
      toast.error("Kullanıcı adı veya şifre boş gönderilemez!!");
    } else {
      axios.post(REACT_APP_API_URL + "ons/adminLogin", data).then((res) => {
        if (res.data.response === true) {
          this.setState({
            showMe: true,
          });
          toast.success("Giriş yapıldı.");
        } else {
          toast.error("Kullanıcı adı veya şifre yanlış!!");
        }
      });
    }
  }

  //gold
  handleSubmit(e) {
    const { REACT_APP_API_URL } = process.env;

    e.preventDefault();
    if (
      this.state.usdBuy === null ||
      this.state.usdBuy === "" ||
      this.state.usdSale === null ||
      this.state.usdSale === "" ||
      this.state.eurBuy === null ||
      this.state.eurBuy === "" ||
      this.state.eurSale === null ||
      this.state.eurSale === "" ||
      this.state.specialBuy === null ||
      this.state.specialBuy === "" ||
      this.state.specialSale === null ||
      this.state.specialSale === "" ||
      this.state.scrap22Buy === null ||
      this.state.scrap22Buy === "" ||
      this.state.scrap22Sale === null ||
      this.state.scrap22Sale === "" ||
      this.state.onsGoldBuy === null ||
      this.state.onsGoldBuy === "" ||
      this.state.onsGoldSale === null ||
      this.state.onsGoldSale === ""
    ) {
      ///hata mesajı
      toast.error("Değerler boş gönderilemez!!");
    } else {
      const objectData = {
        Gold: [
          {
            buy: this.state.specialBuy,
            sales: this.state.specialSale,
            name: "GramAltın",
          },
          {
            buy: this.state.scrap22Buy,
            sales: this.state.scrap22Sale,
            name: "22AyarBilezikGram",
          },
          {
            buy: "1.5",
            sales: "0.1",
            name: "Cumhuriyet",
          },
          {
            buy: "0.1",
            sales: "0.1",
            name: "YarımAltın",
          },

          {
            buy: "0.1",
            sales: "0.1",
            name: "ÇeyrekAltın",
          },
          {
            buy: "0.1",
            sales: "2.4",
            name: "AtaAltın",
          },
          {
            buy: this.state.onsGoldBuy,
            sales: this.state.onsGoldSale,
            name: "OnsAltın/Dolar",
          },
        ],
        Doviz: [
          {
            buy: this.state.usdBuy,
            sales: this.state.usdSale,
            name: "ABDDoları",
          },

          {
            buy: this.state.eurBuy,
            sales: this.state.eurSale,
            name: "Euro",
          },
          {
            buy: "0.1",
            sales: "2.4",
            name: "İngiliz Sterlini",
          },
          {
            buy: "0.1",
            sales: "2.4",
            name: "İsviçreFrangı",
          },
          {
            buy: "0.1",
            sales: "0.1",
            name: "100JaponYeni",
          },
          {
            buy: "0.1",
            sales: "2.4",
            name: "RusRublesi",
          },
          {
            buy: "0.1",
            sales: "2.4",
            name: "ÇinYuanı",
          },
          {
            buy: "0.1",
            sales: "0.1",
            name: "MacaristanForinti",
          },
          {
            buy: "0.1",
            sales: "2.4",
            name: "KanadaDoları",
          },
          {
            buy: "0.1",
            sales: "2.4",
            name: "BrezilyaReali",
          },
        ],
      };
      axios
        .post(REACT_APP_API_URL + "ons/editPrice", objectData)
        .then((res) => {
          toast.success("Değerler güncellendi.");
        });
    }
  }

  render() {
    return (
      <div
        className="container-fluid"
        style={{ background: "white !important" }}
      >
        <div className="container-fluid">
          {this.state.showMe ? (
            <div className="row mt-2 col-xl-12">
              <OnsWorkman />
              <OnsGr />

              <div className="col-lg-6 col-xl-6">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="card card-primary">
                        <div
                          className="card-header"
                          style={{
                            background: "#2c2c2c",
                            color: "#c5a559",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <h5 className="card-title d-flex justify-content-center">
                            MAKAS
                          </h5>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                          <div
                            className="card-body "
                            style={{ background: "#2c2c2c", color: "#c5a559" }}
                          >
                            <div className="form-group row">
                              <label className="col-sm-5 col-form-label">
                                Tür :
                              </label>
                              <label className="col-sm-3 col-form-label">
                                Alış :
                              </label>
                              <label className="col-sm-4 col-form-label">
                                Satış :
                              </label>
                            </div>
                            <hr style={{ border: "1px solid #c5a559" }} />
                            <div className="form-group row">
                              <label className="col-sm-5 col-form-label">
                                USD :
                              </label>
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="usdBuy"
                                  value={this.state.usdBuy}
                                  placeholder="0"
                                />
                              </div>
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="usdSale"
                                  value={this.state.usdSale}
                                  placeholder="0"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-5 col-form-label">
                                EUR :
                              </label>
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="eurBuy"
                                  value={this.state.eurBuy}
                                  placeholder="0"
                                />
                              </div>
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="eurSale"
                                  value={this.state.eurSale}
                                  placeholder="0"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-5 col-form-label">
                                HAS ALTIN :
                              </label>

                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="specialBuy"
                                  value={this.state.specialBuy}
                                  placeholder="0"
                                />
                              </div>
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="specialSale"
                                  value={this.state.specialSale}
                                  placeholder="0"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-5 col-form-label">
                                22 HURDA :
                              </label>
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="scrap22Buy"
                                  value={this.state.scrap22Buy}
                                  placeholder="0"
                                />
                              </div>
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="scrap22Sale"
                                  value={this.state.scrap22Sale}
                                  placeholder="0"
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-lg-5 col-form-label">
                                ONS GOLD :
                              </label>

                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="onsGoldBuy"
                                  value={this.state.onsGoldBuy}
                                  placeholder="0"
                                />
                              </div>
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  className="form-control mb-1"
                                  onChange={this.onChange}
                                  name="onsGoldSale"
                                  value={this.state.onsGoldSale}
                                  placeholder="0"
                                />
                              </div>
                            </div>

                            <div className="mt-2 d-flex justify-content-center">
                              <button
                                type="submit"
                                className="btn btn-dark btn-lg "
                              >
                                Kaydet
                              </button>{" "}
                              &nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <OnsMessage />
            </div>
          ) : (
            <Modal
              visible={this.state.visible}
              width="300"
              height="250"
              effect="fadeInDown"
              onClickAway={() => this.closeModal()}
            >
              <form onSubmit={this.handleSubmitSingIn}>
                <div
                  className="modal-header "
                  style={{ background: "#2c2c2c", color: "#c5a559" }}
                >
                  {" "}
                  <h5 className="text-center">Admin Giriş</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => this.closeModal()}
                  >
                    <h5 style={{ color: "#c5a559" }}>X</h5>
                  </button>
                </div>
                <div
                  className="modal-body"
                  style={{ background: "#2c2c2c", color: "#c5a559" }}
                >
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChange}
                    placeholder="Kullanıcı Adı :"
                  />
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    placeholder="Şifre :"
                  />
                </div>
                <div
                  className="modal-footer "
                  style={{ background: "#2c2c2c", color: "#c5a559" }}
                >
                  <button
                    type="submit"
                    className="btn btn-dark ml-auto mr-auto"
                    data-dismiss="modal"
                  >
                    Giriş Yap
                  </button>
                </div>
              </form>
            </Modal>
          )}
          <ToastContainer />
        </div>
      </div>
    );
  }
}
export default SpicaQuestionAdd;
