import axios from "axios";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Clock from "react-live-clock";
import Modal from "react-awesome-modal";
import { Link } from "react-router-dom";
import Layout from "./Layout";

export default class onsWorkman extends Component {
  constructor() {
    super();
    this.state = {
      visible: true,
      showMe: true,
      username: "",
      password: "",
      newquarterBuy: "0",
      newquarterSale: "0",
      oldquarterBuy: "0",
      oldquarterSale: "0",
      newHalfBuy: "0",
      newHalfSale: "0",
      newUniquenessBuy: "0",
      newUniquenessSale: "0",
      oldUniquenessBuy: "0",
      oldUniquenessSale: "0",
      newGremseBuy: "0",
      newGremseSale: "0",
      oldGremseBuy: "0",
      oldGremseSale: "0",
      newAtaBuy: "0",
      newAtaSale: "0",
      oldAtaBuy: "0",
      oldAtaSale: "0",
      newAtaquintetBuy: "0",
      newAtaquintetSale: "0",
      oldAtaquintetBuy: "0",
      oldAtaquintetSale: "0",
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmitSarraf = this.handleSubmitSarraf.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  //sarraf
  handleSubmitSarraf(e) {
    e.preventDefault();
    const { REACT_APP_API_URL } = process.env;

    const data = {
      SARRAFİYE: [
        {
          buy: this.state.newquarterBuy,
          sales: this.state.newquarterSale,
          name: "Yeni Çeyrek",
        },
        
        {
          buy: this.state.oldquarterBuy,
          sales: this.state.oldquarterSale,
          name: "Eski Çeyrek",
        },
        {
          buy: this.state.newHalfBuy,
          sales: this.state.newHalfSale,
          name: "Yeni Yarım",
        },
        {
          buy: this.state.newUniquenessBuy,
          sales: this.state.newUniquenessSale,
          name: "Yeni Teklik",
        },
        {
          buy: this.state.oldUniquenessBuy,
          sales: this.state.oldUniquenessSale,
          name: "Eski Teklik",
        },
        {
          buy: this.state.newGremseBuy,
          sales: this.state.newGremseSale,
          name: "Yeni Gremse",
        },
        {
          buy: this.state.oldGremseBuy,
          sales: this.state.oldGremseSale,
          name: "Eski Gremse",
        },
        {
          buy: this.state.newAtaBuy,
          sales: this.state.newAtaSale,
          name: "Yeni Ata Lira",
        },
        {
          buy: this.state.oldAtaBuy,
          sales: this.state.oldAtaSale,
          name: "Eski Ata Lira",
        },
        {
          buy: this.state.newAtaquintetBuy,
          sales: this.state.newAtaquintetSale,
          name: "Yeni Ata Beşli",
        },
        {
          buy: this.state.oldAtaquintetBuy,
          sales: this.state.oldAtaquintetSale,
          name: "Eski Ata Beşli",
        },
      ],
    };

    axios.post(REACT_APP_API_URL + "ons/onsWorkmanPut", data).then((res) => {
      toast.success("Değerler güncellendi.");
    });
  }
  componentDidMount() {
    const { REACT_APP_API_URL } = process.env;

    axios.get(REACT_APP_API_URL + "ons/getList").then((res) => {
      const sarraf= res.data.responseWorkman.SARRAFİYE
      console.log(sarraf)
     this.setState({
        newquarterBuy: sarraf[0].buy,
        newquarterSale: sarraf[0].sales,
        oldquarterBuy: sarraf[1].buy,
        oldquarterSale: sarraf[1].sales,
        newHalfBuy: sarraf[2].buy,
        newHalfSale: sarraf[2].sales,
        newUniquenessBuy: sarraf[3].buy,
        newUniquenessSale: sarraf[3].sales,
        oldUniquenessBuy: sarraf[4].buy,
        oldUniquenessSale: sarraf[4].sales,
        newGremseBuy: sarraf[5].buy,
        newGremseSale: sarraf[5].sales,
        oldGremseBuy: sarraf[6].buy,
        oldGremseSale: sarraf[6].sales,
        newAtaBuy: sarraf[7].buy,
        newAtaSale: sarraf[7].sales,
        oldAtaBuy: sarraf[8].buy,
        oldAtaSale: sarraf[8].sales,
        newAtaquintetBuy: sarraf[9].buy,
        newAtaquintetSale: sarraf[9].sales,
        oldAtaquintetBuy: sarraf[10].buy,
        oldAtaquintetSale: sarraf[10].sales,

      })
    });
  }

  render() {
    return (
      <div className="col-lg-6 col-xl-6">
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-primary">
                <div
                  className="card-header"
                  style={{
                    background: "#2c2c2c",
                    color: "#c5a559",
                    borderBottom: "1px solid white",
                  }}
                >
                  <h5 className="card-title d-flex justify-content-center">
                    SARRAFİYE
                  </h5>
                </div>
                <form onSubmit={this.handleSubmitSarraf}>
                  <div
                    className="card-body "
                    style={{ background: "#2c2c2c", color: "#c5a559" }}
                  >
                    <div className="form-group row">
                      <label className="col-sm-5 col-form-label">Tür :</label>
                      <label className="col-sm-3 col-form-label">Alış :</label>
                      <label className="col-sm-4 col-form-label">Satış :</label>
                    </div>
                    <hr style={{ border: "1px solid #c5a559" }} />
                    <div className="form-group row">
                      <label className="col-sm-5 col-form-label">
                        Yeni Çeyrek :
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newquarterBuy"
                          value={this.state.newquarterBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newquarterSale"
                          value={this.state.newquarterSale}
                          placeholder="0"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-5 col-form-label">
                        Eski Çeyrek :
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldquarterBuy"
                          value={this.state.oldquarterBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldquarterSale"
                          value={this.state.oldquarterSale}
                          placeholder="0"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-5 col-form-label">
                        Yeni Yarım :
                      </label>

                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newHalfBuy"
                          value={this.state.newHalfBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newHalfSale"
                          value={this.state.newHalfSale}
                          placeholder="0"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-5 col-form-label">
                        Yeni Teklik :
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newUniquenessBuy"
                          value={this.state.newUniquenessBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newUniquenessSale"
                          value={this.state.newUniquenessSale}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-5 col-form-label">
                        Eski Teklik :
                      </label>

                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldUniquenessBuy"
                          value={this.state.oldUniquenessBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldUniquenessSale"
                          value={this.state.oldUniquenessSale}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-5 col-form-label">
                        Yeni Gremse :
                      </label>

                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newGremseBuy"
                          value={this.state.newGremseBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newGremseSale"
                          value={this.state.newGremseSale}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-5 col-form-label">
                        Eski Gremse :
                      </label>

                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldGremseBuy"
                          value={this.state.oldGremseBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldGremseSale"
                          value={this.state.oldGremseSale}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-5 col-form-label">
                        Yeni Ata Lira :
                      </label>

                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newAtaBuy"
                          value={this.state.newAtaBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newAtaSale"
                          value={this.state.newAtaSale}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-5 col-form-label">
                        Eski Ata Lira :
                      </label>

                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldAtaBuy"
                          value={this.state.oldAtaBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldAtaSale"
                          value={this.state.oldAtaSale}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-5 col-form-label">
                        Yeni Ata Beşli :
                      </label>

                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newAtaquintetBuy"
                          value={this.state.newAtaquintetBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="newAtaquintetSale"
                          value={this.state.newAtaquintetSale}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-5 col-form-label">
                        Eski Ata Beşli :
                      </label>

                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldAtaquintetBuy"
                          value={this.state.oldAtaquintetBuy}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="number"
                          className="form-control mb-1"
                          onChange={this.onChange}
                          name="oldAtaquintetSale"
                          value={this.state.oldAtaquintetSale}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="mt-2 d-flex justify-content-center">
                      <button type="submit" className="btn btn-dark btn-lg ">
                        Kaydet
                      </button>{" "}
                      &nbsp;
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
