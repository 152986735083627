import axios from "axios";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Clock from "react-live-clock";
import Modal from "react-awesome-modal";
import { Link } from "react-router-dom";
import Layout from "./Layout";

export default class onsMessage extends Component {
  constructor() {
    super();

    this.state = {
      usdBuy: "0",
      usdSale: "0",
      eurBuy: "0",
      eurSale: "0",
      specialBuy: "0",
      specialSale: "0",
      scrap22Buy: "0",
      scrap22Sale: "0",
      onsGoldBuy: "0",
      onsGoldSale: "0",
      informationMessage: "",
      visible: true,
      showMe: true,
      username: "",
      password: "",
    };
    this.onChange = this.onChange.bind(this);

    this.handleSubmitInformation = this.handleSubmitInformation.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }
  //information
  handleSubmitInformation(e) {
    const { REACT_APP_API_URL } = process.env;

    e.preventDefault();
    const objectData = {
      informationMessage: this.state.informationMessage,
    };
    if (
      this.state.informationMessage === null ||
      this.state.informationMessage === ""
    ) {
      const objectData = {
        informationMessage:
          "Her Çeşit Toptan Altın-Gümüş Alımı ve Satımı İle Hizmetinizdeyiz",
      };
      axios
        .post(REACT_APP_API_URL + "ons/editMessage", objectData)
        .then((res) => {
          toast.success("Bilgilendirme mesajı güncellendi.");
        });
    } else {
      axios
        .post(REACT_APP_API_URL + "ons/editMessage", objectData)
        .then((res) => {
          toast.success("Bilgilendirme mesajı güncellendi.");
        });
    }
  }
  componentDidMount() {
    const { REACT_APP_API_URL } = process.env;

    axios.get(REACT_APP_API_URL + "ons/getPrice").then((res) => {
      const data = res.data.responseMessage;

      this.setState({
        informationMessage: data.message,
      });
    });
  }
  render() {
    return (
      <div className="col-lg-6 col-xl-6">
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-primary">
                <div
                  className="card-header"
                  style={{
                    background: "#2c2c2c",
                    color: "#c5a559",
                    borderBottom: "1px solid white",
                  }}
                >
                  <h5 className="card-title d-flex justify-content-center">
                    BİLGİLENDİRME ALANI
                  </h5>
                </div>
                <form onSubmit={this.handleSubmitInformation}>
                  <div
                    className="card-body "
                    style={{ background: "#2c2c2c", color: "#c5a559" }}
                  >
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <textarea
                          rows={5}
                          className="form-control"
                          onChange={this.onChange}
                          name="informationMessage"
                          value={this.state.informationMessage}
                          placeholder="Bilgilendirme mesajı giriniz.."
                        />
                      </div>
                    </div>
                    <hr />

                    <div className="mt-2 d-flex justify-content-center">
                      <button type="submit" className="btn btn-dark btn-lg ">
                        Kaydet
                      </button>{" "}
                      &nbsp;
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
