import axios from "axios";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Modal from "react-awesome-modal";
import {Link} from "react-router-dom"
import Layout from "./Layout";

export default class onsGr extends Component {
  constructor() {
    super();
    this.state = {
      visible: true,
      showMe: true,
      username: "",
      password: "",
      gr25Buy: "0",
      gr25Sale: "0",
      gr050Buy: "0",
      gr050Sale: "0",
      gr1Buy916: "0",
      gr1Sale916: "0",
      gr1Buy995: "0",
      gr1Sale995: "0",
      gr5Buy995: "0",
      gr5Sale995: "0",
      gr10Sale995: "0",
      gr10Buy995: "0",
      gr20Buy995: "0",
      gr20Sale995: "0",
      gr50Buy995: "0",
      gr50Sale995: "0",
      gr100Buy995: "0",
      gr100Sale995: "0",
      gr250Buy995: "0",
      gr250Sale995: "0",
      gr500Buy995: "0",
      gr500Sale995: "0",
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmitGr = this.handleSubmitGr.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  //grhandle
  handleSubmitGr(e) {
    e.preventDefault();
    const { REACT_APP_API_URL } = process.env;
    console.log(this.state.gr050Buy)
    const data = {
      GRGOLD: [
        {
          buy: this.state.gr25Buy,
          sales: this.state.gr25Sale,
          name: "0.25GR",
        },
        {
          buy: this.state.gr050Buy,
          sales: this.state.gr050Sale,
          name: "0.50GR916",
        },
        {
          buy: this.state.gr1Buy916,
          sales: this.state.gr1Sale916,
          name: "1.00GR916",
        }, {
          buy: this.state.gr1Buy995,
          sales: this.state.gr1Sale995,
          name: "1.00GR995",
        },
        {
          buy: this.state.gr5Buy995,
          sales: this.state.gr5Sale995,
          name: "5GR995",
        },
        {
          buy: this.state.gr10Buy995,
          sales: this.state.gr10Sale995,
          name: "10GR995",
        },
        {
          buy: this.state.gr20Buy995,
          sales: this.state.gr20Sale995,
          name: "20GR995",
        },
        {
          buy: this.state.gr50Buy995,
          sales: this.state.gr50Sale995,
          name: "50GR995",
        },
        {
          buy: this.state.gr100Buy995,
          sales: this.state.gr100Sale995,
          name: "100GR995",
        },
        {
          buy: this.state.gr250Buy995,
          sales: this.state.gr250Sale995,
          name: "250GR995",
        },{
          buy: this.state.gr500Buy995,
          sales: this.state.gr500Sale995,
          name: "500GR995",
        }
      ],
    };

    axios.post(REACT_APP_API_URL + "ons/onsGrPut", data).then((res) => {
      toast.success("Değerler güncellendi.");
    });
  }
  componentDidMount() {
    const { REACT_APP_API_URL } = process.env;

    axios.get(REACT_APP_API_URL + "ons/getList").then((res) => {
      const grgold= res.data.response.GRGOLD
     this.setState({
      gr25Buy: grgold[0].buy,
      gr25Sale: grgold[0].sales,
      gr050Buy: grgold[1].buy,
      gr050Sale: grgold[1].sales,
      gr1Buy916: grgold[2].buy,
      gr1Sale916: grgold[2].sales,
      gr1Buy995: grgold[3].buy,
      gr1Sale995: grgold[3].sales,
      gr5Buy995: grgold[4].buy,
      gr5Sale995: grgold[4].sales,
      gr10Buy995: grgold[5].buy,
      gr10Sale995: grgold[5].sales,
      gr20Buy995: grgold[6].buy,
      gr20Sale995: grgold[6].sales,
      gr50Buy995: grgold[7].buy,
      gr50Sale995: grgold[7].sales,
      gr100Buy995: grgold[8].buy,
      gr100Sale995: grgold[8].sales,
      gr250Buy995: grgold[9].buy,
      gr250Sale995: grgold[9].sales,
      gr500Buy995: grgold[10].buy,
      gr500Sale995: grgold[10].sales,
      })
    });
  }

  render() {
    return (
 
            <div className="col-lg-6 col-xl-6">
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card card-primary">
                      <div
                        className="card-header"
                        style={{
                          background: "#2c2c2c",
                          color: "#c5a559",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <h5 className="card-title d-flex justify-content-center">
                          GR ALTIN İŞÇİLİK
                        </h5>
                      </div>
                      <form onSubmit={this.handleSubmitGr}>
                        <div
                          className="card-body "
                          style={{ background: "#2c2c2c", color: "#c5a559" }}
                        >
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              Tür :
                            </label>
                            <label className="col-sm-3 col-form-label">
                              Alış :
                            </label>
                            <label className="col-sm-4 col-form-label">
                              Satış :
                            </label>
                          </div>
                          <hr style={{ border: "1px solid #c5a559" }} />
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              0.25 GR :
                            </label>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr25Buy"
                                value={this.state.gr25Buy}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr25Sale"
                                value={this.state.gr25Sale}
                                placeholder="0"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              0.50 GR 916:
                            </label>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr050Buy"
                                value={this.state.gr050Buy}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr050Sale"
                                value={this.state.gr050Sale}
                                placeholder="0"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              1.00 GR 916 :
                            </label>

                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr1Buy916"
                                value={this.state.gr1Buy916}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr1Sale916"
                                value={this.state.gr1Sale916}
                                placeholder="0"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              1.00 GR 995 :
                            </label>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr1Buy995"
                                value={this.state.gr1Buy995}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr1Sale995"
                                value={this.state.gr1Sale995}
                                placeholder="0"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">
                              5 GR 995 :
                            </label>

                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr5Buy995"
                                value={this.state.gr5Buy995}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr5Sale995"
                                value={this.state.gr5Sale995}
                                placeholder="0"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">
                              10 GR 995 :
                            </label>

                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr10Buy995"
                                value={this.state.gr10Buy995}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr10Sale995"
                                value={this.state.gr10Sale995}
                                placeholder="0"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">
                              20 GR 995 :
                            </label>

                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr20Buy995"
                                value={this.state.gr20Buy995}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr20Sale995"
                                value={this.state.gr20Sale995}
                                placeholder="0"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">
                              50 GR 995 :
                            </label>

                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr50Buy995"
                                value={this.state.gr50Buy995}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr50Sale995"
                                value={this.state.gr50Sale995}
                                placeholder="0"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">
                              100 GR 995 :
                            </label>

                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr100Buy995"
                                value={this.state.gr100Buy995}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr100Sale995"
                                value={this.state.gr100Sale995}
                                placeholder="0"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">
                              250 GR 995 :
                            </label>

                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr250Buy995"
                                value={this.state.gr250Buy995}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr250Sale995"
                                value={this.state.gr250Sale995}
                                placeholder="0"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-5 col-form-label">
                              500 GR 995 :
                            </label>

                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr500Buy995"
                                value={this.state.gr250Buy995}
                                placeholder="0"
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                className="form-control mb-1"
                                onChange={this.onChange}
                                name="gr500Sale995"
                                value={this.state.gr250Sale995}
                                placeholder="0"
                              />
                            </div>
                          </div>
                          <div className="mt-2 d-flex justify-content-center">
                            <button
                              type="submit"
                              className="btn btn-dark btn-lg "
                            >
                              Kaydet
                            </button>{" "}
                            &nbsp;
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer/>
            </div>
       
    );
  }
}
