import React from "react";
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import OnsAdd from "./ons/OnsAdd"


const App = () => {
  
  return (
    <Router basename="/">
      <Switch>
        <Route exact path="/" component={OnsAdd}/>
       

      </Switch>
    </Router>
  )}
  export default App;